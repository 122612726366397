import React, { Component } from "react";
import PfApiCall from "../../../redux/API/pfApiCall";
import pfUrlMapping from "../../../redux/API/pfAPI";
import { connect } from "react-redux";
class Interfaces extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gateway: '',
            hostname: '',
            dns_servers: [],
            interfaceData: []
        }
    }
    static getDerivedStateFromProps = (props, state) => {
        if (props.gateway) {
            state.gateway = props.gateway;
            state.hostname = props.hostname;
            state.dns_servers = props.dns;
            state.interfaceData = props.interfaceData
        }
        return state;
    }
    checkManagementInterface = () => {
        let result = false;
        this.state.interfaceData?.map((interfaces) => {
            if (interfaces.type === 'management') {
                result = true;
            }
        })
        return result;
    }
    setAsManagment = (interfaceData) => {
        let data = interfaceData;
        data.type = 'management';
        this.setState({
            showLoader: true,
        });
        PfApiCall(pfUrlMapping.setAsManagement(data), (response) => {
            this.setState({
                showLoader: false,
            });
            if (response.status === 200) {
                this.props.getInterfaces();
            }
        });
    }
    checkError = () => {
        let result = false;
        if (this.state.gateway === '' || this.state.hostname === '' || this.state.dns_servers?.length === 0 ||
            this.state.interfaceData?.length === 0) {
            result = true;
        }
        if (!this.checkManagementInterface()) {
            result = true;
        }
        return result;
    }
    render() {
        return (
            <div className='network-configuration'>
                <div className='step1' id="interfaces">
                    <h1 className="page-title">Configure Network</h1>
                    <span>Configure the network interfaces of the system as well as assign the correct interfaces for the Management type</span>
                    <table className='table aaserver'>
                        <thead>
                            <tr>
                                <th>Status</th>
                                <th>Logical Name</th>
                                <th>IP Address</th>
                                <th>Default Network</th>
                                <th>Type</th>
                                <th className="w-185"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.interfaceData?.map((interfaces) => {
                                return (<tr>
                                    <td>
                                        <div className='d-flex align-items-center'>
                                            <label className="switch"><input type="checkbox" name="advancedOptions"
                                                checked={interfaces.is_running} /><span className="slider round"></span></label>
                                            <span className='ml-2'>Up</span>
                                        </div>
                                    </td>
                                    <td>{interfaces.name}</td>
                                    <td>{interfaces.ipaddress}</td>
                                    <td>{interfaces.network}</td>
                                    <td>{interfaces.type}</td>
                                    <td><button className="btn btn-primary" disabled={interfaces.type === 'management'}
                                        onClick={() => this.setAsManagment(interfaces)}>Set Mangement Interface</button></td>
                                </tr>)
                            })}
                        </tbody>
                    </table>
                    <div className='d-flex align-items-center justify-content-between mb-3 border-bottom'>
                        {!this.checkManagementInterface() &&
                            <div className='text-danger'>At least one interface must be of Type "mangement"</div>
                        }
                    </div>
                    <div className=''>
                        <div className='form-group align-items-center d-flex'>
                            <label className="col-sm-3 text-right">Default Gatway</label>
                            <input
                                type="text"
                                className='form-control col-sm-9'
                                placeholder=''
                                readOnly value={this.state.gateway ? this.state.gateway : ""} />
                        </div>
                        <div className='form-group align-items-center d-flex'>
                            <label className="col-sm-3 text-right">Server Hostname</label>
                            <input
                                type="text"
                                className='form-control col-sm-9'
                                placeholder=''
                                readOnly
                                value={this.state.hostname ? this.state.hostname : ""} />
                        </div>
                        <div className='form-group align-items-center d-flex'>
                            <label className="col-sm-3 text-right">DNS Server</label>
                            <select
                                className='form-control col-sm-9'
                                disabled>
                                {this.state.dns_servers?.map((server) => {
                                    return (<option value={server}>{server}</option>)
                                })}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="s-footer">
                    <button
                        className="btn btn-primary"
                        disabled={this.checkError()}
                        onClick={() => {
                            this.props.nextStep();
                            this.props.stepChange(2);
                        }}>Next</button>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return state;
}
export default connect(mapStateToProps)(Interfaces);
